// import HelloWorld from "@/components/HelloWorld";
import {createRouter, createWebHistory} from "vue-router";
// import store from "@/store";

const routes = [
    {
        path: "/",
        name: "Home",
        // component: "@/views/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/Home"),
        // props: true,
        // meta: {requiresAuth: true},
    },
    {
        path: "/first",
        name: "First",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/FirstView"),
        props: true,
        // meta: {requiresAuth: true},
    },
    {
        path: "/about",
        name: "About",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/AboutUs"),
        props: true,
        // meta: {requiresAuth: true},
    },
    {
        path: "/pledge",
        name: "Loan",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/Loan"),
        props: true,
        // meta: {requiresAuth: true},
    },
    {
        path: "/faq",
        name: "Faq",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/FactQuestions"),
        props: true,
        // meta: {requiresAuth: true},
    },

    {
        path: "/buyback",
        name: "BuyBack",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/BuyBack"),
        props: true,
        // meta: {requiresAuth: true},
    },

    {
        path: "/gdpr",
        name: "GDPR",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/GdprView"),
        props: true,
        // meta: {requiresAuth: true},
    },

    {
        path: "/attractions",
        name: "Attractions",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/AttractionInfo"),
        props: true,
        // meta: {requiresAuth: true},
    },

    {
        path: "/contact",
        name: "ContactUs",
        component: () => import(/* webpackChunkName: "ContactUs" */ "@/views/ContactUs"),
        props: true,
    },

    {
        path: "/leonardo-registrace",
        name: "LeonardoRegister",
        component: () => import(/* webpackChunkName: "ContactUs" */ "@/views/LeonardoRegister.vue"),
        props: true,
    },

    {
        path: "/video",
        name: "Video",
        component: () => import(/* webpackChunkName: "ContactUs" */ "@/views/VideoView"),
        props: true,
    },

    {
        path: "/eshop",
        name: "Shop",
        component: () => import(/* webpackChunkName: "ContactUs" */ "@/views/ShopView"),
        props: true,
        children: [
            // {
            //     path: "",
            //     name: "CatEmpty",
            //     // component: () => import(/* webpackChunkName: "ContactUs" */ "@/components/CategoryList"),
            //     component: () => import(/* webpackChunkName: "ContactUs" */ "@/components/ProductList"),
            //     // props: true,
            // },

            {
                path: ":catName?",
                name: "CatSelected",
                components: {
                    leftSec: () => import(/* webpackChunkName: "ContactUs" */ "@/components/CategoryList"),
                    default: () => import(/* webpackChunkName: "ContactUs" */ "@/components/ProductList"),
                },
                props: true,
            },

            {
                path: ":catName/:itemId",
                name: "ProductDetail",
                components: {
                    leftSec: () => import(/* webpackChunkName: "ContactUs" */ "@/components/CategoryList"),
                    default: () => import(/* webpackChunkName: "ContactUs" */ "@/components/products/ProductDetail"),
                },
                props: true,
            },

        ]
    },


    {
        path: "/cart",
        name: "Basket",
        component: () => import(/* webpackChunkName: "Basket" */ "@/views/TestView"),
        // component: () => import(/* webpackChunkName: "Basket" */ "@/views/BasketView"),
        props: true,
 /*       beforeEnter: (to, from, next) => {
            console.log("Pred vstupem do CARTu")
            if (to.params.page !== "cartList") {
                // if (store.state.basket.length > 0) {
                //     next()
                // } else {
                //     next({name: "Basket", params: {page: "cartList"}})
                // }
            } else {
                next();
            }
        },*/
        children: [
/*            {
                path: "",
                name: "Blank",
                component: () => import(/!* webpackChunkName: "Basket" *!/ "@/components/cart/CartList"),
                props: true,
            },*/
            {
                path: "cartlist",
                name: "CartList",
                component: () => import(/* webpackChunkName: "Basket" */ "@/components/cart/CartList"),
                props: true,
            },
            {
                path: "delivery",
                name: "Delivery",
                component: () => import(/* webpackChunkName: "Basket" */ "@/components/cart/CartDelivery"),
                props: true,
            },
            {
                path: "contact",
                name: "Contact",
                component: () => import(/* webpackChunkName: "Basket" */ "@/components/cart/CartContact"),
                props: true,
            },
            {
                path: "process",
                name: "Process",
                component: () => import(/* webpackChunkName: "Basket" */ "@/components/cart/ProcessOrder"),
                props: true,
            },


        ]

    },

    {
        path: "/thankyou",
        name: "CartThankYou",
        component: () => import(/* webpackChunkName: "Basket" */ "@/components/cart/CartThankYou"),
        props: true,
        children: [
            {
                path: "com",
                name: "ComGate",
                component: () => import(/* webpackChunkName: "Basket" */ "@/components/cart/CartThankYou"),
                props: true,

            },
            {
                path: "csob",
                name: "CSOB",
                component: () => import(/* webpackChunkName: "Basket" */ "@/components/cart/CartThankYou"),
                props: true,

            }
            ],
    },

    {
        path: "/gallery",
        name: "Gallery",
        component: () => import(/* webpackChunkName: "Basket" */ "@/views/GalleryView"),
        props: true,
    },

    {
        path: "/media",
        name: "Media",
        component: () => import(/* webpackChunkName: "Basket" */ "@/views/MediaView"),
        props: true,
    },

    {
        path: "/pgate",
        name: "GateProcess",
        component: () => import(/* webpackChunkName: "GateProcess" */ "@/views/GateProcess"),
        props: true,

    },

    {
        path: "/thank",
        name: "ThankYou",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/ThankYou"),
        props: true,
// meta: {requiresAuth: true},
    }
    ,

    {
        path: "/thankReg",
        name: "ThankYouReg",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Thank you for registration" */ "@/views/ThankYouReg.vue"),
        props: true,
// meta: {requiresAuth: true},
    },

    {
        path: "/article/:artId",
        name: "Article",
        // component: "@/view/Home",
        component: () => import(/* webpackChunkName: "Consultants" */ "@/views/ArticleView"),
        props: true,
// meta: {requiresAuth: true},
    }
    ,


    {
        path: '/:pathMatch(.*)*',
        // alias: "*",
        name: "notFound",
        component: () => import(/* webpackChunkName: "NotFound"*/
            "@/views/NotFound")
    }
]


const router = createRouter(
    {
        routes,
        history: createWebHistory()
    }
)

router.beforeEach(() => {
    window.scrollTo(0, 0)
    init()
    // ...
})


async function init() {
    /*
        if (!store.state.dataInitialized) {
            console.log("Pred initData dispatch");
            await store.dispatch('initData');
        }
    */

}

export default router