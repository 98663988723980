<template>
  <div class="joke" id="joke" :style="{top:  top, left: left}" :class="{'show' : showJoke}">

    <div class="joke-overlay" :class="{dragging}" draggable="true" @drag="doDrag" @dragstart="move" @dragend="moveTo"
         id="joke-over">

      <div class="bubble  pthought " v-html=" jokeText"></div>
<!--      <div class="oval-speech-border  " v-html=" jokeText"></div>-->
      <div class="img-container">
        <span class="close" @click="closeJoke" title="Ukončit zobrazování citací">x</span>
        <img :src="require(`@/assets/${showMan}`)" draggable="false">
      </div>


    </div>
  </div>
</template>

<script>
import support from "@/support/support";

export default {
  name: "JokeComment",
  data() {
    return {
      jokeVisible: false,
      timer: 25,
      toGo: 0,
      start: 5,
      top: "50px",
      left: "200px",
      jokeText: "",
      movedJokePos: false,
      man: 0,
      shouldStart: true,
      startTop: 0,
      startLeft: 0,
      dragging: false,
      maxLeft: 0,
      maxTop: 0,
      intervalRef: null
    }
  },
  props: {
    showJokes: Boolean,
  },
  methods: {

    doDrag() {
      console.log('Draguju');
    },

    move(x) {
      this.dragging = true;
      const left = x.clientX - document.querySelector('#joke-over').getBoundingClientRect().left
      const top = x.clientY - document.querySelector('#joke-over').getBoundingClientRect().top
      this.startTop = left
      this.startLeft = top

    },

    moveTo(x) {
      this.dragging = false;
      const left = x.clientX - this.startTop
      const top = x.clientY - this.startLeft

      this.top = top + "px";
      this.left = left + "px";
      this.movedJokePos = true;
    },

    nextStep: function () { //TODO upravit funcki na nahodne zobrazeni jokes
      ++this.toGo;
      if (this.jokeVisible) {
        if (this.toGo === this.timer) {
          this.jokeVisible = false;
          this.toGo = 0;
          this.shouldStart = true;
        }
      } else {
        if (this.toGo === this.start && this.shouldStart) {
          console.log(("Shuld start:" + this.shouldStart ));
          console.log(("Only start:" + this.start ));
          console.log(("Only toGo:" + this.toGo ));
          this.shouldStart = false;
          const today = new Date();
          console.log("Dalsi krok " + today.toLocaleString());
          if(!this.movedJokePos) {
            this.top = Math.floor((Math.random() * this.maxTop)) + "px";

            this.left = Math.floor((Math.random() * this.maxLeft)) + "px";
          }
          let jokeCount = this.$store.state.jokes.length;
          let selection = Math.floor((Math.random() * jokeCount));
          this.jokeText = support.replaceNextLine( this.$store.state.jokes[selection].joke);
          this.jokeVisible = true;
          let selectionMan = Math.floor((Math.random() * this.$store.state.man.length));
          this.man = selectionMan;
          this.toGo = 0;
        }
      }

    },

    closeJoke() {
      this.jokeVisible = false;
      this.toGo = 0;
      clearInterval(this.intervalRef);
      this.$store.commit('setShowJoke', false);
    }
  },
  computed: {
    showJoke() {
      return this.jokeVisible;
    },

    showMan() {
      console.log("showMan " + this.man);
      return this.$store.state.man[this.man];
    }

  },

  created() {
    this.maxLeft = window.innerWidth - 250;
    this.maxTop = window.innerHeight - 330;

    if (this.showJokes) {
      this.intervalRef = setInterval(this.nextStep, 1000);
    }
  }

}
</script>

<style scoped>
.joke {
  position: fixed;
  top: 50px;
  left: 340px;
  z-index: 110;

  /*display: none;*/
  opacity: 0;
  visibility: hidden;
}

.joke-overlay {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  width: 100%;
  height: 100%;
  /*z-index: 100;*/
  padding: 3px;
  border: 1px solid transparent;
  border-radius: 10px;
}

.joke-overlay:hover{
  border: 1px solid lightgrey;
  background-color: rgba(211, 211, 211, 0.3);
  cursor: grab;
}

.joke-overlay:active{
  cursor: grabbing;
}

.joke-overlay.dragging {
  background-color: rgba(2, 145, 234, 0.19);

}

.img-container{
  width: 300px;
  /*margin-top: -30px;*/
}

.baloon {
  border: 1px solid black;
  border-radius: 5px;
  background-color: rgba(253, 207, 196, 0.99);
  padding: 5px;
  margin-left: -50px;
  max-width: 200px;
  margin-bottom: 10px;
}

img {
  width: 100px;
  margin: 0 auto;

}

.show {
  /*display: inherit;*/
  transition: opacity 600ms, visibility 1000ms;
  visibility: visible;
  opacity: 1;
}

span.close {
  position: absolute;
  bottom: 5px;
  right: 80px;
  display: block;
  /*float: right;*/
  font-size: small;
  font-weight: bold;
  margin-right: 1px;
  /*border: solid 1px lightgray;*/
  padding: 0 1px;
  cursor: pointer;
  /*background-color: white;*/
  z-index: 120;
}


</style>