<template>
  <section class="hz-button">
<!--    <button class="button text-black" :class="button_type">-->
    <button class="button text-black rose" >
      <slot/>
      <slot name="test">
      </slot>
    </button>
  </section>
</template>

<script>
export default {
  name: "HZButton",

}
</script>

<style scoped>
.hz-button{
  display: inline-block;
}

</style>