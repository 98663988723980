<template>
  <button class="button text-black button-frame" >
    <slot/>
    <slot name="test">
    </slot>
  </button>
</template>

<script>
export default {
  name: "HZBaseButton"
}
</script>

<style scoped>

</style>