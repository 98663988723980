<template>
<div class="container footer rose">
  <div class="simple-menu">
    <span class="menu-item">
      <HZLink :to="{name: 'Home'}"> Home</HZLink>
    </span>
    <span class="menu-item">
      <HZLink :to="{name: 'Article', params: {artId: 'vsob'}}">Všeobecné obchodní podmínky</HZLink>
    </span>
    <span class="menu-item">
      <HZLink :to="{name: 'Article', params: {artId: 'gdpr'}}">GDPR</HZLink>
    </span>
  </div>
  <div>Copyright ©2023 <a href="/">Iva Pluháčková</a> - All rights reserved</div>
</div>
</template>

<script>
export default {
  name: "FootBlock"
}
</script>

<style scoped>
.container{
  padding: 50px ;
  margin: auto;
  /*background-color: #23718a;*/
  color: black

}

a{
  text-decoration: none;
  /*color: #013672;*/
  color: darkgray;
}

a:hover{
  color: black;
  text-decoration: underline;
}

/*.footer{
  position: fixed;
  bottom: 0;
}*/

.container{
  margin: auto 0 0;
}

.menu-item{
  display: inline-block;
  cursor: pointer;
  margin: 20px 0;
  padding: 0 10px;
}

.menu-item:hover{
  text-decoration: underline;
}

.menu-item:not(:last-child){
  border-right: solid 1px lightgray;
}

</style>