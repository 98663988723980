<template >
  <TheNavigation />
  <router-view :key="$route.path" ></router-view>
<!--  <BaseRulesBlock/>-->
  <FootBlock />
  <JokeComment v-if="jokeStatus" :show-jokes = "jokeStatus"/>
</template>

<script>
import TheNavigation from "@/components/TheNavigation";
import FootBlock from "@/components/FootBlock";
import JokeComment from "@/views/JokeComment";
// import BaseRulesBlock from "@/components/BaseRulesBlock";
export default {
  name: 'App',
  components: {
    JokeComment,
    // BaseRulesBlock,
    FootBlock,
    TheNavigation,
  },

  computed:{
    jokeStatus(){
      return this.$store.state.showJoke;
    }
  }

}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
