import axios from "axios";
// import router from "@/router";
import store from "@/store";

export default async function gPost( baseUrl, par, suc, fail, apiToken =  store.state.apiToken) {
   let param = {
        ...par,
        shopId: store.state.shopId
    }
    return axios.post(baseUrl, param, {
        validateStatus: function () {
            return true;
        },
        headers:{
            // 'Authorization': apiToken,
            'HZ-API': apiToken,
            'Access-Control-Allow-Origin': '*'
        }
    }).then(response => {
            const resultError = response.data.ERROR;
            if (resultError) {
                // if(response.data.respCode === 401){
                //     router.push({name: 'Login'});
                // }
                fail(response.data);
            } else {
                suc(response.data);
            }
        }
    ).catch(response => {
            response.msg = "Nelze komunikovat se serverem. Chyba spojení."
            fail(response);

        }
    )
}
