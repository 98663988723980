import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store";
import router from "@/routes";
import '@/assets/hz_general.css';
import '@/assets/hz_local.css';
import VueCookies from "vue-cookies";
import HZLink from "@/support/HZLink";
import HZButton from "@/support/HZButton";
import HZBaseButton from "@/support/HZBaseButton";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import imageZoom from 'vue-image-zoomer';

let Vue = createApp(App)
    .component('HZLink', HZLink)
    .component('HZButton', HZButton)
    .component('hz-base-button', HZBaseButton)
    .component('image-zoom', imageZoom)
    .use(store)
    .use(router)
    .use(VueReCaptcha, {siteKey: "6LeG370fAAAAAFLx8DYRDaxZ_M6p_6C3lA0Q5Fyf" })
    .use(VueCookies, {expire: '7d'})
    .mount('#app');

// Práce s cookies
const user = {id: 1, name: 'Journal', session: '25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX'};
Vue.$cookies.set('user',user);
Vue.$cookies.set('cart',"Informace");
// print user name
// console.log(Vue.$cookies.get('user').name);
// console.log(Vue.$cookies.get('cart'));
// console.log(Vue.$cookies.keys());

store.$cookies = Vue.$cookies; // Možnost přiřazení proměnné do storu, případně jinéh modulu. Tam se volají this.$cookies
store.aNecoJineho = "Tady je neco JINEHO";

// Načtení externího config souboru
console.log("Home Dir " + process.env.BASE_URL);
console.log("Home Dir " + window.location.origin);
fetch(process.env.BASE_URL + "config.json")
    .then((response) => {
            // console.log("Status "+ response.status);
            return response.json();
    })
    .then((config) => {
        // console.log(config);
        store.commit('setApiToken', config.apiToken); // Zápis informací z configuračního souboru do storu
        store.commit('setBaseApiUrl', config.baseApiUrl);
        store.commit('setDataInitialized', true);
        store.commit('setShopId', config.shopId);
        store.commit('setShopLoc', config.shopLoc);
        store.commit('setStoreUrl', config.storeRefURL);
        store.commit('setPacketaApiKey', config.packetaApiKey);
        store.commit('setShowJoke', config.showJoke);
        store.commit('setShowShop', config.showShop);
        store.dispatch('initData')
            .then(() => console.log("Data loaded"))
            .then(() => {
                const basketId = localStorage.getItem("basketId")
                if(basketId){
                    store.commit('setBasketId', basketId);
                    // store.dispatch('getTmpBasketData', basketId);
                    store.dispatch('getBasketDataFromLocal', localStorage.getItem('basketData'));
                    console.log("Basket nastaven");
                }
            });
        console.log("Asi hotovo");

    })


