<template>
  <div>
    <div class="top_container">
      <div><img class="page-logo" src="@/assets/Logo_black.svg"></div>

      <div class='nav'>
        <ul :class="{active:setActive}">
          <li>
            <HZLink :to="{name:'Home'}" @click="setInactive">
              Home
            </HZLink>
          </li>

          <li >
            <HZLink :to="{name: 'Article', params:{artId: 'IVA_A_0003'}}" @click="setInactive">
              O mně
            </HZLink>
          </li>
<!--          <li>-->
<!--            <HZLink :to="{name:'Video'}" @click="setInactive">-->
<!--              Videa-->
<!--            </HZLink>-->
<!--          </li>-->

          <!--
                    <li>
                      <span>
                        O nás
                        <i class='fa fa-caret-down'></i>
                      </span>

                      <ul class='menu'>
                        <li>
                          <HZLink :to="{name:'About'}">
                            O nás
                          </HZLink>
                        </li>
                      </ul>

                    </li>
          -->


          <!--          <li>-->
          <!--            <HZLink :to="{name:'Faq'}">-->
          <!--              Další služby-->
          <!--            </HZLink>-->

          <!--          </li>-->

<!--          <li>-->
<!--            <HZLink :to="{name:'Gallery'}" @click="setInactive">-->
<!--              Galerie-->
<!--            </HZLink>-->
<!--          </li>-->

          <li>
            <HZLink :to="{name:'Media'}" @click="setInactive">
              Media
            </HZLink>
          </li>

          <li>
            <HZLink :to="{name:'ContactUs'}" @click="setInactive">
              Kontakt
            </HZLink>
          </li>

<!--          <li>-->
<!--                      <span>Etiketa Leonardo &nbsp;-->
<!--                                  <i class='fa fa-caret-down'></i>-->
<!--                                </span>-->
<!--            <ul class='menu'>-->
<!--              <li>-->
<!--                <HZLink :to="{name:'LeonardoRegister'}" @click="setInactive">-->
<!--                  Registrace-->
<!--                </HZLink>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </li>-->


          <li v-if="this.$store.state.showShop">
            <span>
              E-shop
              <i class='fa fa-caret-down'></i>
            </span>

            <ul class='menu'>
              <li>
                <HZLink :to="{name:'CatSelected', params:{catName: 'All'}}" @click="setInactive">
                  Všechny produkty
                </HZLink>
              </li>
              <li v-for="cat in catList" :key="cat.catId" class="cat-item">
                <HZLink :to="{name: 'CatSelected', params:{catName: cat.urlName}}" @click="setInactive">
                  {{ cat.shortName }}
                </HZLink>
              </li>

            </ul>

          </li>
        </ul>

      </div>

      <div v-if="this.$store.state.showShop" class="cart-container">
        <!--      <div class="text_small line_double pointer text_red hover" @click="logOut">Odhlásit</div>-->
        <span class="cart-ref">
        <HZLink :to="{name: 'CartList'}" @click="setInactive">
          <div class="cart">
            <img :src="require(`@/assets/cart-icon.png`)">
            <div v-if="basketSize > 0" class="cart-items">{{ basketSize }}</div>
          </div>
        </HZLink>
                </span>
      </div>


      <div class="toggle-nav" @click="changeActive">&#9776;</div>
    </div>

  </div>
</template>

<script>
// import {mapGetters} from "vuex";

import {mapGetters} from "vuex";

export default {
  name: "TheNavigation",
  components: {},
  data() {
    return {
      showLogOutDialog: false,
      // destinations: store.destinations,
      setActive: true,
    }
  },

  computed: {

    ...mapGetters({
      basketSize: 'getBasketSize',
    }),

    catList() {
      return this.$store.state.category;
    },


  },

  methods: {
    changeActive() {
      this.setActive = !this.setActive;

    },

    setInactive() {
      this.setActive = true;
    }
  }

}
</script>

<style scoped>

.page-logo {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.fa-caret-down{
  font-size: .8em;
}


@media screen and (max-width: 860px) {
  .page-logo {
    margin-right: 20px;
  }

}
</style>